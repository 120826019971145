var ko = require("knockout");
var BrandingHelpers = require("./BrandingHelpers");
var Browser = require("./BrowserControl");
var Constants = require("./Constants");

var w = window;
var LayoutTemplateType = Constants.LayoutTemplateType;

function ContentControl(params)
{
    
    var _this = this;

    var _serverData = params.serverData;
    

    
    var _staticTenantBranding = _serverData.staticTenantBranding;
    

    
    _this.isVerticalSplitTemplate = ko.observable(false);
    

    
    function _initializeTemplate(staticTenantBranding)
    {
        var tenantBranding = BrandingHelpers.loadTenantBranding(staticTenantBranding);
        var layoutTemplateConfig = BrandingHelpers.getLayoutTemplateConfig(tenantBranding);
        var layoutType = layoutTemplateConfig.layoutType;
        _this.isVerticalSplitTemplate(layoutType === LayoutTemplateType.VerticalSplit);
    }

    (function _initialize()
    {
        _initializeTemplate(_staticTenantBranding);
    })();
    
}

ko.components.register("content-control",
    {
        viewModel: ContentControl,
        template: require("html/Shared/Controls/ConvergedContentControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = ContentControl;